<script setup>
import CtaButton from '@/Components/Shared/Elements/CtaButton.vue'
import StorageBadge from '@/Components/Shared/Elements/StorageBadge.vue'
import Breadcrumb from '@/Components/Shared/Navigation/Breadcrumb.vue'
import helper from '@/Utils/helper'
import { usePage } from '@inertiajs/inertia-vue3'
import { computed } from 'vue'
import StreamsBadge from '@/Components/Shared/Elements/StreamsBadge.vue'

const user = computed(() => usePage().props.value.auth.user)
</script>

<template>
  <div
    class="px-4 border-b border-neutral-300 dark:border-neutral-800 items-center py-4 flex"
  >
    <Breadcrumb />
    <div class="mr-0 flex flex-col lg:flex-row items-center">
      <span
        v-if="user.trail_period"
        class="mr-4 order-2 lg:order-none mt-4 lg:mt-0 js-trial-period-duration text-sm"
      >
        <template v-if="user.trail_period === 'Expired'"
          >Trial period has expired</template
        >
        <template
          v-else-if="user.trail_period !== null && user.trail_period < 7"
        >
          Trial period ends
          <strong>{{ helper.formatReadableDateDiff(user.trail_ends) }}</strong>
        </template>
      </span>
      <div class="inline-flex w-full lg:w-auto order-1">
        <StreamsBadge
          :active-streams="user.streams_running"
          :max-streams="user.max_streams"
          tooltip-message="Number of streams you can run"
        />

        <StorageBadge
          v-if="user?.upload_disk !== 'local'"
          :taken-storage="user.taken_storage"
          :total-storage="user.total_storage"
        />

        <CtaButton
          variant="rounded"
          size="tiny"
          color="outlined"
          border-style="faint"
          url="/billing"
          type="link"
          class="block"
        >
          Manage <span class="hidden lg:inline">plan</span>
        </CtaButton>
      </div>
    </div>
  </div>
</template>
